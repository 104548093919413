<template>
  <div
    :class="{
      active:
        currentUser.active_project &&
        currentUser.active_project.id == project.id,
      'project-admin-link':
        !project.archived && ['super_admin', 'admin'].includes(currentUser.active_team_role)
    }"
    class="project"
    @click="goToProjectSettings(project.id)"
  >
    <div
      class="project__actions"
      v-if="!project.archived && ['super_admin', 'admin'].includes(currentUser.active_team_role)"
    >
      <v-btn icon @click:stop="goToProjectSettings(project.id)">
        <v-icon>$cog</v-icon>
      </v-btn>
    </div>
    <div
      class="project__unpublished"
      v-if="!project.published"
    >
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
        >
          $eye_off
        </v-icon>
      </template>
      <span>This opportunity has been hidden from the opportunity page.</span>
    </v-tooltip>
    </div>
    <div
      class="project__preview"
      :style="{ backgroundImage: `url(${project.preview.thumb.url})` }"
    >
      <Avatar
        v-if="!project.preview.thumb.url"
        :id="project.id"
        :name="project.title"
      />
      <v-icon class="project__selected" color="primary"
        >$circle_checkbox_on</v-icon
      >
    </div>
    <div class="project__container">
      <div class="project__title">{{ project.title }}</div>
      <div class="project__description">
        by <u style="color: #8e8989">{{ project.team_name }}</u>
        <div v-if="project.archived">
          Will be deleted on
          <span>{{
            willBeDeletedOn(project.archived_at)
              | moment(
                "timezone",
                currentUser.active_project
                  ? currentUser.active_project.timezone
                  : "America/New_York",
                "MM/DD"
              )
          }}</span>
        </div>
        <!-- <div v-if="project.description" :title="project.description">
          {{ project.description }}
        </div>
        <div v-else>
          No description
        </div> -->
      </div>

      <div class="project__overlay">
        <v-btn
          v-if="!project.archived"
          outlined
          @click.prevent="$emit('select', project)"
        >
          Go to Opportunity
        </v-btn>
        <v-btn
          v-else
          outlined
          @click.prevent="$emit('restore', project)"
        >
          Restore
        </v-btn>
      </div>
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: Object
  },
  methods: {
    async goToProjectSettings(pid) {
      if(!['super_admin', 'admin'].includes(this.currentUser.active_team_role)) return;
      if(this.project.archived) return;
      await this.$api.Project.makeActive({ id: pid });
      this.$root.$emit("fetchEnv");
      this.$router.push(`/projects/${pid}/edit`);
    },
    willBeDeletedOn(archived_at) {
      let d = new Date(archived_at);
      d.setDate(d.getDate() + 30);
      return d;
    },
  }
}
</script>

<style lang="scss" scoped>
.project {
  margin: 40px 20px;
  background: white;
  width: 200px;
  min-height: 200px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: 0.3s;
  position: relative;
  color: var(--v-text-base);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  .env-production & {
    background:  black;
    color: white;
  }

  &.project-admin-link {
    cursor: pointer;
  }

  &--new {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--v-primary-base);

    &:hover {
      filter: brightness(105%);
    }
  }
  &--new &__title {
    color: white;
    font-size: 16px;
    margin-top: 0;
  }

  &.active &__preview {
    border-color: var(--v-primary-base);
  }

  &:hover {
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
  }
  &:hover &__description {
    color: var(--v-text-base);
  }

  &__unpublished {
    position: absolute;
    top: -35px;
    left: 0;
  }

  &__actions {
    position: absolute;
    top: -35px;
    right: 0;
    opacity: 0;
    transition: 0.3s;
  }

  &:hover &__actions {
    opacity: 1;
  }

  &__selected {
    position: absolute;
    top: -5px;
    right: -5px;
    background: white;
    border-radius: 50%;
    display: none;
  }

  &.active &__selected {
    display: flex;
  }

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;
  }

  &__preview {
    position: relative;
    width: 80px;
    height: 80px;
    background: #e7e7e7;
    border-radius: 50%;
    border: 5px solid white;
    display: inline-block;
    margin-top: -40px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    background-size: cover;
    background-position: center;

    &::v-deep {
      .avatar {
        position: absolute;
        left: 0;
        right: 0;
        width: 70px;
        height: 70px;
        font-size: 24px;
      }
    }
  }
  &__title {
    font-weight: bold;
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.2;
    padding: 0 15px;
    min-height: 32px;
    overflow: hidden;
  }

  &__description {
    font-size: 12px;
    padding: 15px;
    line-height: 1.2;
    color: #a0a0a0;
    transition: 0.3s;
    min-height: 58px;
    overflow: hidden;
    flex-grow: 1;
  }

  &__small-action {
    font-size: 12px;
    color: #a0a0a0;
    margin-top: .5em;
    text-decoration: underline;
  }
}
</style>