import Vue from "vue";
import { axios } from "./api.config";
import { crud, objectToFormData } from "@/lib";

Vue.prototype.$http = axios;

const Auth = {
  login: (params) => axios.post("/auth/login", params),
  register: (params) => axios.post("/auth/register", params),
  resetPassword: (params) => axios.post("/auth/reset_password", params),
  setPassword: (params) => axios.post("/auth/set_password", params),
  checkPasswordToken: (params) =>
    axios.post("/auth/check_password_token", params),
};

const Datasources = {
  get: (params) => axios.get("/datasources", { params, jwt: true }),
};

const Referral = {
  get: (params) => axios.get("/referrals", { params, jwt: true }),
};

const Logs = {
  get: (params) => axios.get("/logs", { params, jwt: true }),
};

const Dashboard = {
  events: (params) => axios.get("/dashboard/events", { params, jwt: true }),
  pages: (params) => axios.get("/dashboard/pages", { params, jwt: true }),
  counters: (params) => axios.get("/dashboard/counters", { params, jwt: true }),
};

const Reports = {
  counters: (params) => axios.get("/reports/counters", { params, jwt: true }),
};

const Release = {
  ...crud("releases"),
};

const Badge = {
  ...crud("badges"),
  lite: () => axios.get("badges/lite", { jwt: true }),
};

const Specialty = {
  ...crud("specialties"),
  lite: () => axios.get("specialties/lite", { jwt: true }),
};

const CustomComponent = {
  ...crud("custom_components"),
  lite: () => axios.get("custom_components/lite", { jwt: true }),
};

const ImageAsset = {
  ...crud("image_assets"),
};

const Search = {
  search: (params) => axios.get("search", { params, jwt: true }),
};

const Deployment = {
  ...crud("deployments"),
  cancel: (params) => axios.post("/deployments/cancel", params, { jwt: true }),
};

const Theme = {
  ...crud("themes"),
  getActive: () => axios.get("/themes/active", { jwt: true }),
  lite: (params) => axios.get("themes/lite", { params, jwt: true }),
};

const Profile = {
  get: () => axios.get("/profile/current", { jwt: true }),
  setEnv: (params) => axios.put("/profile/set_env", params, { jwt: true }),
  update: (params, additional) =>
    axios.put("/profile/update", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
  onboard: (params, additional) =>
    axios.put("/profile/onboard", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
  unlock: (params) => axios.post("/profile/unlock", params, { jwt: true }),
};

const Project = {
  ...crud("projects"),
  makeActive: (params) =>
    axios.put(`/projects/${params.id}/make_active`, params, { jwt: true }),
  duplicate: (params) =>
    axios.post(`/projects/duplicate`, params, { jwt: true }),
  archive: (params) =>
    axios.put(`/projects/${params.id}/archive`, params, { jwt: true }),
  unarchive: (params) =>
    axios.put(`/projects/${params.id}/unarchive`, params, { jwt: true }),
  templates: () => axios.get(`/projects/templates`, { jwt: true }),
  makeFeatured: (params) =>
    axios.put(`/projects/${params.id}/make_featured`, params, { jwt: true }),
  makeNotFeatured: (params) =>
    axios.put(`/projects/${params.id}/make_not_featured`, params, {
      jwt: true,
    }),
};

const Addon = {
  ...crud("addons"),
};

const Event = {
  ...crud("events"),
  lite: () => axios.get("events/lite", { jwt: true }),
  duplicate: (params) => axios.post(`/events/duplicate`, params, { jwt: true }),
};

const SubmissionForm = {
  ...crud("submission_forms"),
  lite: () => axios.get("submission_forms/lite", { jwt: true }),
  duplicate: (params) =>
    axios.post(`/submission_forms/duplicate`, params, { jwt: true }),
};

const RegistrationForm = {
  ...crud("registration_forms"),
  lite: () => axios.get("registration_forms/lite", { jwt: true }),
  duplicate: (params) =>
    axios.post(`/registration_forms/duplicate`, params, { jwt: true }),
};

const Submission = {
  ...crud("submissions"),
  lite: (params) => axios.get("submissions/lite", { params, jwt: true }),
  bulkPublish: (params) =>
    axios.post(`submissions/bulk_publish`, params, { jwt: true }),
  bulkUnpublish: (params) =>
    axios.post(`submissions/bulk_unpublish`, params, {
      jwt: true,
    }),
};

const Presentation = {
  ...crud("presentations"),
  duplicate: (params) =>
    axios.post(`/presentations/duplicate`, params, { jwt: true }),
};

const Page = {
  ...crud("pages"),
  lite: () => axios.get("pages/lite", { jwt: true }),
  duplicate: (params) => axios.post(`/pages/duplicate`, params, { jwt: true }),
  updateComponents: (params) =>
    axios.put(`pages/${params.id}/components`, params, { jwt: true }),
  menuUp: (params) =>
    axios.put(`pages/${params.id}/menu_up`, params, { jwt: true }),
  menuDown: (params) =>
    axios.put(`pages/${params.id}/menu_down`, params, { jwt: true }),
  footerUp: (params) =>
    axios.put(`pages/${params.id}/footer_up`, params, { jwt: true }),
  footerDown: (params) =>
    axios.put(`pages/${params.id}/footer_down`, params, { jwt: true }),
};

const Team = {
  ...crud("teams"),
  exit: (params) =>
    axios.put(`/teams/${params.id}/exit`, params, { jwt: true }),
  makeActive: (params) =>
    axios.put(`/teams/${params.id}/make_active`, params, { jwt: true }),
};

const Invite = {
  register: (params) => axios.post(`/invites/register`, params, { jwt: true }),
  accept: (params) => axios.put(`/invites/accept`, params, { jwt: true }),
  reject: (params) => axios.put(`/invites/reject`, params, { jwt: true }),
  get: (params) => axios.get("invites/get", { params, jwt: true }),
};

const User = {
  ...crud("users"),
  lite: () => axios.get("users/lite", { jwt: true }),
  confirm: (params) =>
    axios.put(`/users/${params.id}/confirm`, params, { jwt: true }),
  sendClaimEmail: (params) =>
    axios.put(`/users/${params.id}/send_claim_email`, params, { jwt: true }),
  createClaimLink: (params) =>
    axios.put(`/users/${params.id}/create_claim_link`, params, { jwt: true }),
};

const Schedule = {
  ...crud("schedules"),
  lite: () => axios.get("schedules/lite", { jwt: true }),
  duplicate: (params) =>
    axios.post(`/schedules/duplicate`, params, { jwt: true }),
};

const ScheduleItem = {
  ...crud("schedule_items"),
  lite: (params) => axios.get("schedule_items/lite", { params, jwt: true }),
};

const Admin = {
  ...crud("admins"),
};

const Role = {
  ...crud("roles"),
  lite: () => axios.get("roles/lite", { jwt: true }),
};

const InternalProject = {
  ...crud("internal_projects"),
  lite: () => axios.get("internal_projects/lite", { jwt: true }),
};

const Countdown = {
  ...crud("countdowns"),
  duplicate: (params) =>
    axios.post(`/countdowns/duplicate`, params, { jwt: true }),
};

const ContentSection = {
  ...crud("content_sections"),
  lite: () => axios.get("content_sections/lite", { jwt: true }),
  duplicate: (params) =>
    axios.post(`/content_sections/duplicate`, params, { jwt: true }),
  updateBlocks: (params) =>
    axios.put(`/content_sections/${params.id}/blocks`, params, { jwt: true }),
};

const ContentBlock = {
  ...crud("content_blocks"),
  duplicate: (params) =>
    axios.post(`/content_blocks/duplicate`, params, { jwt: true }),
};

const SupportForm = {
  ...crud("support_forms"),
  duplicate: (params) =>
    axios.post(`/support_forms/duplicate`, params, { jwt: true }),
};

const SupportTicket = {
  ...crud("support_tickets"),
  getInContext: (params) =>
    axios.get(`support_tickets/${params.id}/get_in_context`, {
      params,
      jwt: true,
    }),
};

const SubmissionContent = {
  ...crud("submission_contents"),
};

const List = {
  ...crud("lists"),
  lite: () => axios.get("lists/lite", { jwt: true }),
  duplicate: (params) => axios.post(`/lists/duplicate`, params, { jwt: true }),
};
const ListEntry = {
  ...crud("list_entries"),
  lite: (params) => axios.get("list_entries/lite", { params, jwt: true }),
};

const Notification = {
  ...crud("notifications"),
  sendNotification: (params) =>
    axios.post(`/notifications/send_notification`, params, { jwt: true }),
  duplicate: (params) =>
    axios.post(`/notifications/duplicate`, params, { jwt: true }),
};

const HtmlBlock = {
  ...crud("html_blocks"),
  duplicate: (params) =>
    axios.post(`/html_blocks/duplicate`, params, { jwt: true }),
};

const TextBlock = {
  ...crud("text_blocks"),
  duplicate: (params) =>
    axios.post(`/text_blocks/duplicate`, params, { jwt: true }),
};

const Faq = {
  ...crud("faqs"),
  duplicate: (params) => axios.post(`/faqs/duplicate`, params, { jwt: true }),
};

const TimelineList = {
  ...crud("timeline_lists"),
  duplicate: (params) =>
    axios.post(`/timeline_lists/duplicate`, params, { jwt: true }),
};

const Chat = {
  ...crud("chats"),
  deleteMessage: ({ message_id }) =>
    axios.delete(`/chats/delete_message/${message_id}`, { jwt: true }),
};

const ProfileReview = {
  ...crud("profile_reviews"),
};

const School = {
  ...crud("schools"),
  search: (params) => axios.get("/schools/search", { params, jwt: true }),
};

const Geo = {
  search: (params) => axios.get("geo/search", { params, jwt: true }),
};

const GDriveImageLibrary = {
  list: () => axios.get("gdrive_image_library/list", { jwt: true }),
  getImage: (params) =>
    axios.get("gdrive_image_library/get_image", {
      params,
      jwt: true,
      responseType: "blob",
    }),
};

const ProfileNotification = {
  ...crud("profile_notifications"),
};

const QbProject = {
  ...crud("qb_projects"),
  lite: () => axios.get("qb_projects/lite", { jwt: true }),
  search: (params) => axios.get("qb_projects/search", { params, jwt: true }),
};

const QbClass = {
  lite: () => axios.get("qb_classes/lite", { jwt: true }),
};

Vue.prototype.$api = {
  Geo,
  Theme,
  Admin,
  Auth,
  User,
  Datasources,
  Project,
  Profile,
  Team,
  Event,
  Role,
  Chat,
  SupportForm,
  Dashboard,
  Reports,
  ContentSection,
  ContentBlock,
  Countdown,
  List,
  ListEntry,
  Notification,
  TextBlock,
  Schedule,
  ScheduleItem,
  Invite,
  Page,
  SubmissionForm,
  Submission,
  Addon,
  HtmlBlock,
  Faq,
  TimelineList,
  SupportTicket,
  SubmissionContent,
  Release,
  Deployment,
  Presentation,
  CustomComponent,
  Search,
  ImageAsset,
  RegistrationForm,
  Logs,
  Badge,
  School,
  Specialty,
  Referral,
  GDriveImageLibrary,
  ProfileNotification,
  QbProject,
  InternalProject,
  ProfileReview,
  QbClass,
};
