var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project",class:{
    active:
      _vm.currentUser.active_project &&
      _vm.currentUser.active_project.id == _vm.project.id,
    'project-admin-link':
      !_vm.project.archived && ['super_admin', 'admin'].includes(_vm.currentUser.active_team_role)
  },on:{"click":function($event){return _vm.goToProjectSettings(_vm.project.id)}}},[(!_vm.project.archived && ['super_admin', 'admin'].includes(_vm.currentUser.active_team_role))?_c('div',{staticClass:"project__actions"},[_c('v-btn',{attrs:{"icon":""},on:{"click:stop":function($event){return _vm.goToProjectSettings(_vm.project.id)}}},[_c('v-icon',[_vm._v("$cog")])],1)],1):_vm._e(),(!_vm.project.published)?_c('div',{staticClass:"project__unpublished"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" $eye_off ")])]}}],null,false,2464184082)},[_c('span',[_vm._v("This opportunity has been hidden from the opportunity page.")])])],1):_vm._e(),_c('div',{staticClass:"project__preview",style:({ backgroundImage: ("url(" + (_vm.project.preview.thumb.url) + ")") })},[(!_vm.project.preview.thumb.url)?_c('Avatar',{attrs:{"id":_vm.project.id,"name":_vm.project.title}}):_vm._e(),_c('v-icon',{staticClass:"project__selected",attrs:{"color":"primary"}},[_vm._v("$circle_checkbox_on")])],1),_c('div',{staticClass:"project__container"},[_c('div',{staticClass:"project__title"},[_vm._v(_vm._s(_vm.project.title))]),_c('div',{staticClass:"project__description"},[_vm._v(" by "),_c('u',{staticStyle:{"color":"#8e8989"}},[_vm._v(_vm._s(_vm.project.team_name))]),(_vm.project.archived)?_c('div',[_vm._v(" Will be deleted on "),_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.willBeDeletedOn(_vm.project.archived_at), "timezone", _vm.currentUser.active_project ? _vm.currentUser.active_project.timezone : "America/New_York", "MM/DD" )))])]):_vm._e()]),_c('div',{staticClass:"project__overlay"},[(!_vm.project.archived)?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('select', _vm.project)}}},[_vm._v(" Go to Opportunity ")]):_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('restore', _vm.project)}}},[_vm._v(" Restore ")])],1),_vm._t("actions")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }