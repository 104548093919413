<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="UGC Submission Info">
        <FormRow
          :key="field.uuid"
          v-for="field in form.submission_form.submission_form"
          :label="['Checkbox'].includes(field.type) ? '' : field.label"
          :required="field.required"
          :clean="['HtmlBlock', 'Checkbox', 'Radio'].includes(field.type)"
        >
          <component
            :is="`Dynamic${field.type}`"
            v-bind="field"
            v-model="form.data[field.uuid]"
            :rules="field.required ? [validation.required] : []"
          ></component>
        </FormRow>
      </FormPanel>

      <FormPanel label="UGC Submission Details">
        <FormRow label="User">
          <UsersSelector v-model="form.user_id"></UsersSelector>
        </FormRow>
        <FormRow
          label="Finalist"
          tooltip="Check the box if this submission is in finalists list."
        >
          <Checkbox v-model="form.finalist"></Checkbox>
        </FormRow>
        <FormRow
          label="Winner"
          tooltip="Check the box if this submission is a winner."
        >
          <Checkbox v-model="form.winner"></Checkbox>
        </FormRow>
        <!-- <FormRow
          label="Published"
          tooltip="Check the box if this submission is clear to be published on your site."
        >
          <Checkbox v-model="form.published"></Checkbox>
        </FormRow> -->
        <FormRow
          label="Submission Form"
          tooltip="Select the Submission Form the entry should populate."
        >
          <div class="mt-1">
            <router-link
              :to="`/widgets/submission-forms/${form.submission_form.id}/edit`"
              >{{ form.submission_form.title }}</router-link
            >
          </div>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../../_extends/form.extends.js";

export default {
  extends: mixin,
};
</script>

<style></style>
