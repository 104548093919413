<template>
  <div>
    <TextArea placeholder="Label" v-model="localValue.label" />
    <br/>
    <Select
      label="Allowed file types"
      placeholder="Allowed file types"
      :items="allowedFileTypeOptions"
      v-model="localValue.allowed_file_types"
      item-value="value"
      item-text="name"
    />
    <br/>
    <TextField label="File Size Limit (MB)" placeholder="File Size Limit (MB)" v-model="localValue.file_size_limit" type="number" />
    <Checkbox
      class="file-input-checkbox"
      label="File Input"
      v-model="localValue.file_input"
    />
  </div>
</template>

<script>
import mixin from "./form-builder.mixin.js";
export default {
  extends: mixin,
  data() {
    return {
      allowedFileTypeOptions: [
        { name: 'Any', value: 'any' },
        { name: 'Images', value: 'images' },
        { name: 'Videos', value: 'videos' },
        { name: 'Images or Videos', value: 'images_or_videos' },
        { name: 'PDF or Doc', value: 'pdf_or_doc' },
      ]
    }
  },
};
</script>

<style lang="scss" scoped>
.file-input-checkbox {
  margin-top: -1px;
  margin-left: 20px;
}
</style>
