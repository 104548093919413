import Vue from "vue";

[
  "FileField",
  "TextField",
  "WaitingApproval",
  "TextArea",
  "RadioGroup",
  "Checkbox",
  "ColorPicker",
  "DateTimePicker",
  "FormBuilder",
  "Banner",
  "Uploader",
  "UploaderWithBgPosition",
  "Spinner",
  "Search",
  "ContextSearch",
  "Dropdown",
  "SubmitFooter",
  "IndexPageActions",
  "Pagination",
  "Stories",
  "Actions",
  "Slides",
  "Alert",
  "ConfirmDialog",
  "RecaptchaText",
  "ThemesTabs",
  "Avatar",
  "Switcher",
  "CodeEditor",
  "Addon",
  "SubmissionLayoutBuilder",
  "EnvSelectorDialog",
  "MetaTagsEditor",
  "ThemeTemplate",
  "JsonViewer",
  "SubmissionStage",
  "ProjectTile",
  "WorkExamplesEditor",
  "WorkExampleEditorSlides",
  "SchoolSearch",
  "ImageCropper",
  "ProjectCodeField",
].forEach((e) => {
  Vue.component(e, require(`./${e}.vue`).default);
});

[
  "TextBlock",
  "Countdown",
  "ContentSection",
  "Event",
  "List",
  "Schedule",
  "SupportForm",
  "SubmissionForm",
  "HtmlBlock",
  "SubmissionContent",
  "Presentation",
  "CustomComponent",
  "RegistrationForm",
  "Faq",
  "TimelineList",
].forEach((e) => {
  Vue.component(`Page${e}`, require(`./page-components/${e}.vue`).default);
});

["FormRow", "FormPanels", "FormPanel"].forEach((e) => {
  Vue.component(e, require(`./form/${e}.vue`).default);
});

[
  "AddonEmailPasswordLogin",
  "AddonCasLogin",
  "AddonTwitterLogin",
  "AddonLinkedinLogin",
  "AddonFacebookLogin",
  "AddonGoogleLogin",
  "AddonVimeoUpload",
  "AddonYoutubeUpload",
  "AddonGa",
].forEach((e) => {
  Vue.component(e, require(`./addon-forms/${e}.vue`).default);
});

["ListPanels", "ListPanel"].forEach((e) => {
  Vue.component(e, require(`./list/${e}.vue`).default);
});

[
  "InternalProjectsSelector",
  "LocationSelector",
  "BadgeSelector",
  "BadgePickSelector",
  "TimezoneSelector",
  "EnvSelector",
  "ThemesSelector",
  "SchedulesSelector",
  "TagSelector",
  "ListsSelector",
  "NotificationTypeSelector",
  "TextBlockLayoutSelector",
  "FontSelector",
  "Select",
  "EventsSelector",
  "PagesSelector",
  "RolesSelector",
  "ContentSectionsSelector",
  "ProjectSelector",
  "ProjectsSelector",
  "UsersSelector",
  "SubmissionFormSelector",
  "SubmissionContentLayoutSelector",
  "CustomComponentsSelector",
  "SkillsSelector",
].forEach((e) => {
  Vue.component(e, require(`./selects/${e}.vue`).default);
});

[
  "PresentationImage",
  "PresentationRotator",
  "PresentationVimeo",
  "PresentationYoutube",
].forEach((e) => {
  Vue.component(e, require(`./presentation/${e}.vue`).default);
});

[
  "TextField",
  "TextArea",
  "EmailField",
  "NumberField",
  "MediaField",
  "Checkbox",
  "Radio",
  "Select",
  "HtmlBlock",
  "PasswordField",
  "PhoneField",
  "LinkField",
  "DateField",
  "HiddenField",
].forEach((e) => {
  Vue.component(
    `FormBuilder${e}`,
    require(`./form-builder/${e}.form-builder.vue`).default
  );
});

[
  "TextField",
  "TextArea",
  "EmailField",
  "NumberField",
  "MediaField",
  "PhoneField",
  "Checkbox",
  "Radio",
  "HtmlBlock",
  "LinkField",
].forEach((e) => {
  Vue.component(
    `Dynamic${e}`,
    require(`./dynamic-inputs/${e}.dynamic.vue`).default
  );
});

["NextBtn", "BackBtn"].forEach((e) => {
  Vue.component(`Onboarding${e}`, require(`./onboarding/${e}.vue`).default);
});

Vue.component(
  "ProfileRating",
  require(`./profile-review/ProfileRating.vue`).default
);
